<template>
  <div>
    <div class="content-header cols">
      <div class="cols">
        <h2>
          <span v-if="create">{{ $tfo('create | case') }}</span>
          <span v-else>
            {{ tab === 'info' ? $tfo('edit | case') : $tfo('case') }}
          </span>
        </h2>
        <div class="mar-1" v-show="tab === 'uploads'">
          <el-button
            name="create-btn"
            class="header-button"
            circle
            icon="el-icon-plus"
            size="small"
            @click="createButtonHandler"
            :disabled="$hasNoPermission(['ffsecurity_incidents.add_case'])"
          />
        </div>
      </div>
      <div class="content-header-tabs">
        <common-tabs v-model="tab" :items="tabs" :converter="$tfo" class="item-tabs"></common-tabs>
      </div>
    </div>

    <div v-if="tab === 'info'">
      <!-- Form -->
      <case-form-item ref="formItem" :item="item" :loading="loading" :editable="true"></case-form-item>

      <!-- Buttons -->
      <div class="mar-top--large">
        <el-button v-if="tab !== 'chart'" name="save-btn" type="primary" @click="saveHandler" :disabled="isSaveButtonDisabled">{{
          $tf('common.save')
        }}</el-button>
        <el-button name="back-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('common.back') }}</el-button>
      </div>

      <div class="mar-top--large">
        <confirm-button
          target="case"
          size="mini"
          plain
          @confirm="deleteHandler"
          :disabled="$hasNoPermission('ffsecurity_incidents.delete_case')"
          v-if="!create"
        >
        </confirm-button>
      </div>
    </div>

    <div v-if="tab === 'faces'">
      <case-faces :case-id="id" @search="casesSearchHandler" @search-external="externalSearchHandler" :video-filter="videoFilter" />
    </div>

    <!-- Chart -->
    <div v-if="tab === 'uploads'">
      <video-table :filter="videoFilter" :cases="true" />
    </div>

    <cases-search-dialog v-if="dialog.searchCases.enabled" @close="() => (dialog.searchCases.enabled = false)" :item="dialog.searchCases.item">
    </cases-search-dialog>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import qs from 'qs';
import ConfirmButton from '../confirm/confirm-button';
import VideoTable from '../videos/video.table';
import CaseFaces from './case.faces';
import CaseFormItem from './case.form';
import CasesSearchDialog from './cases.search.dialog';

export default {
  name: 'case-form',
  components: {
    CasesSearchDialog,
    CaseFormItem,
    CaseFaces,
    VideoTable,
    ConfirmButton
  },
  data: function () {
    return {
      formContent: {
        image: null
      },
      tab: 'info',
      create: true,
      item: {},
      loading: false,
      dialog: {
        searchCases: {
          enabled: false
        }
      },
      videoFilter: { limit: 1000, save_to: `cases:${this.$route.params.id}`, ordering: 'id' }
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    tabs() {
      return this.item.id ? [{ name: 'info' }, { name: 'faces' }, { name: 'uploads' }] : [{ name: 'info' }];
    },
    state() {
      return this.$store.state.cases;
    },
    isSaveButtonDisabled() {
      return (
        (this.create && this.$hasNoPermission('ffsecurity_incidents.add_case')) || (!this.create && this.$hasNoPermission('ffsecurity_incidents.change_case'))
      );
    }
  },
  watch: {
    tab(v) {
      const filterString = (this.$route.params && this.$route.params.filter) || '';
      if (v === 'faces' && filterString.indexOf('case_in') > -1) return;

      let path = `/cases/${this.id}/${v}`;
      if (v === 'faces') path += '/filter/' + qs.stringify({ case_in: [this.id], limit: 10 });
      this.$router.push(path);
    }
  },
  created() {
    this.item = _.cloneDeep(this.state.item.empty);
  },
  mounted() {
    const tab = this.$route.params.tab;
    this.tab = tab || 'info';
    this.load();
  },
  methods: {
    load() {
      let id = this.$route.params.id;
      this.create = !id;
      if (id) {
        this.loading = true;
        this.$store
          .dispatch(this.state.Action.Get, { id: decodeURIComponent(id) })
          .then((v) => {
            this.item = v;
          })
          .catch((e) => {
            this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    saveHandler() {
      this.$refs.formItem.validate(this.validateHandler);
    },
    validateHandler(valid) {
      if (!valid) return;
      this.save(this.item);
    },
    save(item) {
      let action = this.create ? this.state.Action.Create : this.state.Action.Update;
      this.$store
        .dispatch(action, item)
        .then((v) => {
          this.create = false;
          this.item.id = v.id;
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.$router.push({ path: `/cases/${v.id}/` });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    deleteHandler() {
      this.$store
        .dispatch(this.state.Action.Delete, this.item)
        .then(() => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.$router.push({ path: '/cases/filter/limit=10' });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    cancelHandler() {
      this.$router.backTo({ path: '/cases/filter/limit=10' });
    },
    createButtonHandler() {
      this.$router.push({ path: `/videos/create/save_to=cases:${this.item.id}` });
    },
    casesSearchHandler(caseFace) {
      this.dialog.searchCases.item = caseFace;
      this.dialog.searchCases.enabled = true;
    },
    externalSearchHandler(item) {
      this.$store.state.dialog.puppeteer_search.dossier = null;
      this.$store.state.dialog.puppeteer_search.case_face = item.id;
      this.$store.state.dialog.puppeteer_search.item = null;
      this.$store.state.dialog.puppeteer_search.enabled = true;
    }
  }
};
</script>

<style>
.case-chart {
  position: relative;
  max-width: 64rem;
  height: 480px;
}
</style>
