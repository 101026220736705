<template>
  <el-form
    v-loading="loading"
    :size="$vars.sizes.form"
    :label-position="$vars.forms.label_position"
    :labelWidth="$vars.forms.label_width"
    ref="form"
    :model="item"
    :rules="rules"
    :name="$options.name"
  >
    <el-form-item :label="$tf('id')" v-if="item.id">
      <span name="id">{{ item.id }}</span>
    </el-form-item>

    <el-form-item :label="$tf('name')" prop="name">
      <el-input name="name" v-model="item.name"></el-input>
    </el-form-item>

    <el-form-item :label="$tf('comment')" prop="comment">
      <el-input name="comment" v-model="item.comment"></el-input>
    </el-form-item>

    <el-form-item :label="$tfo('kusp_id')" prop="claim_id">
      <el-input name="claim_id" v-model="item.claim_id"></el-input>
    </el-form-item>

    <el-form-item :label="$tfo('kusp_date')" prop="claim_creation_date">
      <el-date-picker type="datetime" name="claim_creation_date" v-model="item.claim_creation_date" clearable></el-date-picker>
    </el-form-item>

    <el-form-item :label="$tfo('incident_date')" prop="incident_date">
      <el-date-picker type="datetime" name="incident_date" v-model="item.incident_date" clearable></el-date-picker>
    </el-form-item>

    <el-form-item prop="active">
      <el-checkbox name="active" v-model="item.active">{{ $tf('common.active') }}</el-checkbox>
    </el-form-item>
  </el-form>
</template>
<script>
const baseRules = {
  name: [{ required: true, message: 'error.required.field_name', trigger: 'change', min: 2 }],
  claim_id: [{ required: true, message: 'error.required.field_name', trigger: 'change', min: 2 }],
  claim_creation_date: [{ required: true, message: 'error.required.field', trigger: 'change', validator: dateCustomValidator }],
  incident_date: [{ required: true, message: 'error.required.field', trigger: 'change', validator: dateCustomValidator }]
};

function dateCustomValidator(rule, value, cb) {
  const errorMessage = typeof value === 'string' || value instanceof Date ? undefined : rule.tmessage || rule.message; // FFSER-6112
  cb(errorMessage);
}

export default {
  name: 'case-form-item',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: this.$applyRuleMessages(baseRules)
    };
  },
  methods: {
    validate(cb) {
      return this.$refs.form.validate(cb);
    }
  }
};
</script>
