<template>
  <el-dialog
    name="search-cases-dialog"
    :title="$tfo('search | cases,,1')"
    :visible="true"
    :modal="true"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    :show-close="true"
    :width="'50%'"
    @close="$emit('close')"
  >
    <div style="margin: 2rem">
      <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
        <el-form-item :label="$tf('face | id')">
          <span>{{ item.id }}</span>
        </el-form-item>

        <el-form-item :label="$tfo('confidence_threshold')">
          <confidence-select-proxy v-model="filter.threshold" />
        </el-form-item>
      </el-form>
    </div>

    <p class="center-text">
      <el-button name="search-btn" type="primary" @click="search">{{ $tfo('search') }}</el-button>
    </p>
  </el-dialog>
</template>
<script>
import _ from '@/apps/common/lodash';
import ConfidenceSelectProxy from '../common/confidence.select.proxy';

export default {
  name: 'cases-search-dialog',
  components: { ConfidenceSelectProxy },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    const filter = this.getFilter();
    return {
      filter
    };
  },
  watch: {
    item: {
      handler: function () {
        this.filter = this.getFilter();
      },
      deep: true
    }
  },
  methods: {
    getFilter() {
      const state = this.$store.state.cases,
        looks_like = `caseface:${this.item.id}`,
        threshold = this.$store.getters.objectConfidenceThresholds.face_confidence_threshold,
        r = { ..._.cloneDeep(state.filter.empty), looks_like, threshold };
      return r;
    },
    search() {
      this.$router.push({
        path: `/cases/filter/limit=10&looks_like=${this.filter.looks_like}&threshold=${this.filter.threshold}`
      });
    }
  }
};
</script>
